<template>
  <div>
    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>
    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
          <div class="row pb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="invoice_code">{{ $t('sales_refund.invoice_code') }}</label>
              <input v-model="filters.invoice_code" type="text" id="invoice_code" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('sales_refund.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('sales_refund.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_amount">{{ $t('sales_refund.from_amount') }}</label>
              <input v-model="filters.from_amount" type="number" id="from_amount" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_amount">{{ $t('sales_refund.to_amount') }}</label>
              <input v-model="filters.to_amount" type="number" id="to_amount" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="customer_id">{{ $t('sales_refund.customer') }}</label>
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :placeholder="$t('sales_refund.customer')"
                  label="fullname"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('sales_refund.collected_by') }}</label>
              <multiselect
                  v-model="user"
                  :placeholder="$t('sales_refund.added_by')"
                  label="name"
                  track-by="id"
                  :options="users"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>


            <div class="col-lg-6 mt-2 mb-2">
              <label>{{ $t('sales_refund.payment_status') }}</label>
              <select name="" id="payment_status" v-model="filters.payment_status" class="custom-select">
                <option v-for="row in payment_status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Filter -->
    <!--begin::customer-->
    <div class="card card-custom">

      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="client_info" slot-scope="props">
            <div>
              <div><span>{{ $t('sales_refund.customer_code') }}: </span><span>{{ props.row.customer_code }}</span></div>
              <div><span>{{ $t('sales_refund.customer_name') }}: </span><span>{{ props.row.customer_name }}</span></div>
            </div>
          </template>
          <template slot="refund_info" slot-scope="props">
            <div>
              <div><span>{{ $t('sales_refund.invoice_code') }}: </span><span>{{ props.row.invoice_code }}</span></div>
              <div><span>{{ $t('created_at') }}: </span><span>{{ props.row.create_date }}</span></div>
            </div>
          </template>
          <template slot="refund_receipt_details" slot-scope="props">
            <div v-if="props.row.invoice_refund_receipt_code"><span>{{ $t('sales_refund.code_refund_receipt') }}: </span><span>{{ props.row.invoice_refund_receipt_code }}</span></div>
            <div v-if="props.row.invoice_refund_receipt_date"><span>{{ $t('sales_refund.refund_receipt_date') }}: </span><span>{{ props.row.invoice_refund_receipt_date }}</span></div>
            <div>
              <span>({{ props.row.status_name }})</span>
            </div>
            <div>
              <span>{{ $t('sales_refund.amount') }}: </span><span>{{ props.row.invoice_refund_total }}</span>
            </div>
            <div>
              <span>{{ $t('paid_amount') }}: </span><span>{{ props.row.total_paid_amount }}</span>
            </div>
            <div><span>{{ $t('sales_refund.currency') }}: </span><span>{{ props.row.currency_name }}</span></div>
            <div><span>{{ $t('sales_invoices.payment_status') }}: </span><span>({{ props.row.is_paid_status_name }})</span></div>

            <div v-if="props.row.attachment_url">
              <a :href="props.row.attachment_url" target="_blank">{{ $t('sales_refund.attachment') }}</a>
            </div>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="$can('sales_refund.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{$t('change_status')}} ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">

            <v-btn icon v-b-tooltip.hover :title="$t('edit')" v-if="$can('sales_refund.update')" color="pink" :to="`/sales/sales_refund/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>

            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('sales_refund.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            <!-- <div>
                <b-dropdown right :text="$t('more_actions')">
                    <b-dropdown-item @click="showDetails(props.row.id)">{{$t('sales_refund.details')}}</b-dropdown-item>
                </b-dropdown>
            </div> -->

            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('return_and_payment') " variant="outline-primary btn-sm" class="m-2">
              <router-link  v-if="props.row.status == 2 && props.row.is_paid != 1" class="dropdown-item" :to="`/sales/payment_sales_invoices/create/${props.row.id}/sales_refund`">
                {{ $t('MENU.payment_sales_refund') }}
              </router-link>
            </b-dropdown>

            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('invoice_and_procedures') " variant="outline-primary btn-sm" class="m-2">

              <div class="dropdown-item d-flex justify-content-between" v-if="$can('sales_refund.pdf_print_tax_invoice')">
                <label>{{ $t('invoice') }}</label>
                <div class="d-flex justify-content-between">
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales-tax-invoice-refund/${props.row.id}/print`">
                    <i class="fas fa-print"></i>
                  </router-link>
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales-tax-invoice-refund/${props.row.id}/pdf`">
                    <i class="fas fa-file-pdf"></i>
                  </router-link>
                </div>
              </div>


              <div class="dropdown-item d-flex justify-content-between" v-if="$can('sales_refund.printing_export_pdf')">
                <label>{{ $t('MENU.invoice_printing_80_mm') }}</label>
                <div class="d-flex justify-content-between">
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales-refund-80/${props.row.id}/print`">
                    <i class="fas fa-print"></i>
                  </router-link>
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales-refund-80/${props.row.id}/pdf`">
                    <i class="fas fa-file-pdf"></i>
                  </router-link>
                </div>
              </div>



<!--              <div class="dropdown-item d-flex justify-content-between" v-if="$can('sales_refund.pdf_print_tax_invoice_simplified')">-->
<!--                <label>{{ $t('tax_invoice_printing_simplified') }}</label>-->
<!--                <div class="d-flex justify-content-between">-->
<!--                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales-tax-invoice-refund/${props.row.id}/print/simplified`">-->
<!--                    <i class="fas fa-print"></i>-->
<!--                  </router-link>-->
<!--&lt;!&ndash;                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales-tax-invoice-refund/${props.row.id}/pdf/simplified`">&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="fas fa-file-pdf"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->


            </b-dropdown>



<!--            <b-dropdown id="dropdown-offset" :text="$t('more_actions') " variant="outline-primary" class="m-2">-->


<!--              <router-link v-if="$can('sales_refund.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/sales-refund/${props.row.id}/print`">-->
<!--                {{ $t('MENU.refund_receipt') }}-->
<!--              </router-link>-->
<!--              <router-link v-if="$can('sales_refund.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/sales-refund/${props.row.id}/pdf`">-->
<!--                {{ $t('export_invoice_pdf') }}-->
<!--              </router-link>-->

<!--              <router-link  v-if="$can('sales_refund.pdf_print_tax_invoice')" target="_blank" class="dropdown-item" :to="`/print/sales-tax-invoice-refund/${props.row.id}/print`">-->
<!--                {{ $t('tax_invoice_printing') }}-->
<!--              </router-link>-->
<!--              <router-link target="_blank" v-if="$can('sales_refund.pdf_print_tax_invoice')" class="dropdown-item" :to="`/print/sales-tax-invoice-refund/${props.row.id}/pdf`">-->
<!--                {{ $t('export_tax_invoice_pdf') }}-->
<!--              </router-link>-->

<!--              <router-link target="_blank" v-if="$can('sales_refund.pdf_print_tax_invoice_simplified')" class="dropdown-item" :to="`/print/sales-tax-invoice-refund/${props.row.id}/print/simplified`">-->
<!--                {{ $t('tax_invoice_printing_simplified') }}-->
<!--              </router-link>-->
<!--              <router-link target="_blank" v-if="$can('sales_refund.pdf_print_tax_invoice_simplified')" class="dropdown-item" :to="`/print/sales-tax-invoice-refund/${props.row.id}/pdf/simplified`">-->
<!--                {{ $t('export_tax_invoice_pdf_simplified') }}-->
<!--              </router-link>-->


<!--            </b-dropdown>-->
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

export default {
  name: "index-sales_refund",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/sales_refunds',
      routeChangeStatus: 'sales/sales_refund/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        invoice_code: null,
        from_date: null,
        to_date: null,
        from_amount: null,
        to_amount: null,
        customer_id: null,
        collected_user_id: null,
        payment_method: null,
        payment_status: null,
        invoiced_by_id: null,
      },
      columns: ['#','client_info', 'refund_info','refund_receipt_details', 'status', 'actions'],
      data: [],
      payment_methods: [],
      payment_status_list: [],
      users: [],
      innerId: null,
      statusId: null,
      status_list: [],
      customers: [],
      customer: null,
      user: null,
    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          client_info: that.$t('sales_refund.client_info'),
          refund_info: that.$t('sales_refund.refund_info'),
          refund_receipt_details: that.$t('sales_refund.refund_receipt_details'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.collected_user_id = val.id;
      } else {
        this.filters.collected_user_id = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_refund")}]);
    this.getPaymentMethods();
    this.getPaymentStatus();
    // this.getCustomers();
    // this.getUsers();
    this.getStatusList();
  },
  methods: {
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.invoice_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.from_amount = null;
      this.filters.to_amount = null;
      this.filters.customer_id = null;
      this.filters.collected_user_id = null;
      this.filters.payment_method = null;
      this.filters.payment_status = null;
      this.filters.invoiced_by_id = null;
      this.user = null;
      this.customer = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getPaymentStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.payment_status_list = response.data.data;
      });
    },

    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    showDetails(id) {
      this.$router.push({name: 'sales_refund.details', params: {id: id}});
    }
  },
};
</script>
